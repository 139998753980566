import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AppTranslationModule } from 'app/app.translation.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgaModule } from 'app/theme/nga.module';
import { DisableControlDirective } from './directives/disable-control.directive';
import { NgxUploaderModule } from 'ngx-uploader';
import { RecaptchaDirective } from './directives/reCaptcha/reCaptcha.directive';
import { PINCodeModal } from './modals/pin-code/pincode-modal.component';
import { PinCodeComponent } from './controls/pin-code/pin-code.component';
import { InfoModal } from './modals/default/info-modal.component';
import { DecimalOnlyDirective, NumberOnlyDirective } from './directives/number-only-directive';
import { FileUploadComponent } from './controls/file-uploader/app-file-upload-control';
import { BlockCopyPasteDirective } from './directives/block-cut-copy-paste.directive';
import { ChangePasswordPinModal } from 'app/pages/login/user-profile/change-password-pin/change-password-pin.component';
import { PaymentLinkModal } from 'app/pages/accounts/payment-link-modal/payment-link-modal';
import { PaymentNotifyModal } from 'app/pages/payment-notify/payment-notify-modal/payment-notify-modal';
import { PaypalModal } from 'app/pages/accounts/paypal-modal/paypal-modal';
import { ActivityFeed } from './feed/activity-feed/activity-feed.component';
import { Feed } from './feed/feed.component';
import { TrafficChart } from 'app/pages/dashboard/trafficChart';
import { StatsComponent } from './stats/stats';
import { NgbModal, NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomNgbDateParserFormatter } from './bootstrap-custom/ngbDateFormatter';
import { DateComponent } from './controls/date-picker/date.component';
import { AccessCodeModal } from './modals/access-code/accessCode-modal.component';

@NgModule({
  declarations: [
    TrafficChart,
    BlockCopyPasteDirective,
    DisableControlDirective,
    RecaptchaDirective,
    DecimalOnlyDirective,
    NumberOnlyDirective,
    PinCodeComponent,
    PINCodeModal,
    InfoModal,
    ChangePasswordPinModal,
    PaymentLinkModal,
    FileUploadComponent,
    PaymentNotifyModal,
    PaypalModal,
    ActivityFeed,
    Feed,
    StatsComponent,
    DateComponent,
    AccessCodeModal
  ],
  imports: [
    CommonModule,
    AppTranslationModule,
    ReactiveFormsModule,
    FormsModule,
    NgxUploaderModule,
    NgaModule,
    NgbModule

  ],
  exports: [
    CommonModule,
    AppTranslationModule,
    ReactiveFormsModule,
    FormsModule,
    NgxUploaderModule,
    NgaModule,
    TrafficChart,
    FileUploadComponent,
    BlockCopyPasteDirective,
    DisableControlDirective,
    PinCodeComponent,
    RecaptchaDirective,
    DecimalOnlyDirective,
    NumberOnlyDirective,
    ActivityFeed,
    Feed,
    StatsComponent,
    AccessCodeModal,
  ],
  entryComponents: [
    PINCodeModal,
    InfoModal,
    ChangePasswordPinModal,
    PaymentLinkModal,
    PaymentNotifyModal,
    PaypalModal,
    AccessCodeModal
  ],
  providers: [
    CurrencyPipe,
    { provide: NgbDateParserFormatter, useFactory: () => new CustomNgbDateParserFormatter('longDate') }
  ]
})
export class SharedModule { }
